import cn from "classnames";
import { useTranslation } from "react-i18next";
import {
  useRef,
  useEffect,
  useState,
  Suspense,
  useCallback,
  useMemo,
} from "react";
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { Header } from "features/MainPage/Header";
import { MobileMenu } from "features/MainPage/MobileMenu";
import { Footer } from "shared/components/Footer";
import { Text } from "shared/components/Text";
import { Button } from "shared/components/Button";
import firstShadow from "assets/images/shadow-1.png";
import secondShadow from "assets/images/shadow-2.png";

import { GoogleMaps } from "./GoogleMaps";
import { AboutSection } from "./sections/AboutSection";
import { PriceListSection } from "./sections/PriceListSection";
import { CareerSection } from "./sections/CareerSection";
import { CooperationSection } from "./sections/CooperationSection";
import { FAQSection } from "./sections/FAQSection";
import { ContactSection } from "./sections/ContactSection";
import { VoucherSection } from "./sections/VoucherSection";
import css from "./styles.module.scss";
import { Toast } from "./Toast";
import { ReviewSection } from "./sections/ReviewSection";
import { ResetLocationButton } from "shared/components/ResetLocationButton";
import { LanguageChooser } from "shared/components/LanguageChooser";
import { NewsletterSection } from "./sections/NewsletterSection";
import { LoadingOverlay } from "shared/components/LoadingOverlay";

import { CancelOverlay } from "./CancelOverlay";
import { appStore } from "store";
import { getCity, getLocations } from "store/selectors";
import { DescriptionSection } from "./sections/DescriptionSection";

const isIos = () => {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "MacIntel",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

export const MainPage = () => {
  const { t, i18n } = useTranslation();
  // const [locations, setLocations] = useState(null);
  const { reservationStatus } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const bookingId = searchParams.get("booking_id");
  const personId = searchParams.get("person_id");

  const [shouldShowCancelOverlay, setShouldShowCancelOverlay] = useState(
    !!(bookingId && personId)
  );

  const { city } = appStore.useState(getCity);
  const { locations } = appStore.useState(getLocations);

  const currentCityConfig = locations.cities[city];

  const backgroundVideo = useMemo(() => {
    if (!locations || !city || !currentCityConfig) return null;
    return currentCityConfig.mainSection.backgroundVideo;
  }, [locations, city]);

  const backgroundMobileVideo = useMemo(() => {
    if (!locations || !city || !currentCityConfig) return null;
    return currentCityConfig.mainSection.backgroundMobileVideo;
  }, [locations, city]);

  const vimeoBackgroundVideoURL = useMemo(() => {
    if (!locations || !city || !currentCityConfig) return null;
    return currentCityConfig.mainSection.vimeoBackgroundVideoURL;
  }, [locations, city]);

  const vimeoMobileBackgroundVideoURL = useMemo(() => {
    if (!locations || !city || !currentCityConfig) return null;
    return currentCityConfig.mainSection.vimeoMobileBackgroundVideoURL;
  }, [locations, city]);

  const isCooperationSectionVisible = useMemo(() => {
    if (!locations || !city || !currentCityConfig) return false;
    return currentCityConfig.cooperationSection.cooperationSectionVisibility;
  }, [locations, city]);

  const navigate = useNavigate();

  const [mobileMenuVisibility, setMobileMenuVisibility] = useState();
  const [toastText, setToastText] = useState("");
  const [requestFailed, setRequestFailed] = useState(false);
  const [isNewsletterRequestLoading, setIsNewsletterRequestLoading] =
    useState(false);

  const homeRef = useRef();
  const aboutRef = useRef();
  const priceListRef = useRef();
  const careerRef = useRef();
  const cooperationRef = useRef();
  const classesRef = useRef();
  const voucherRef = useRef();
  const contactRef = useRef();
  const faqRef = useRef();
  const reviewsRef = useRef();
  const newsletterRef = useRef();

  useEffect(() => {
    const getBasketValueAndSendPurchaseEvent = async () => {
      const plan4uApiUrl = currentCityConfig.mainSection.plan4uApiUrl;
      const basketId = searchParams.get("bGUID");

      if (!basketId) {
        setToastText(t("shared.success"));
        return;
      }

      let response;
      let amount;
      let id;
      try {
        response = await (
          await fetch(`${plan4uApiUrl}/o_basketInfo/${basketId}`, {
            method: "GET",
          })
        ).json();
        amount = response.data[0].amount / 100;
        id = response.data[0].id;
      } catch (e) {
        const err = `Error while retrieving basket: ${e}`;
        console.error(err);
        setToastText(t("shared.success"));
        return;
      }

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          value: amount,
          currency: "PLN",
          transaction_id: id,
          coupon: null,
        },
      });

      setToastText(t("shared.success"));
    };

    if (reservationStatus === "success" && currentCityConfig) {
      getBasketValueAndSendPurchaseEvent();
    }
  }, [reservationStatus, currentCityConfig]);

  useEffect(() => {
    if (!currentCityConfig) {
      navigate("/");
      return;
    }

    homeRef.current.scrollIntoView();
    document.title = currentCityConfig.cityOptions.siteTitle;
  }, [currentCityConfig, navigate]);

  const sections = useMemo(() => {
    let sectionsArray = [
      { title: t("header.home"), ref: homeRef, visibility: currentCityConfig.mainSection.isVisible },
      { title: t("header.aboutUs"), ref: aboutRef, visibility: currentCityConfig.aboutSection.isVisible },
      { title: t("header.priceList"), ref: priceListRef, visibility: currentCityConfig.priceSection.isVisible },
      { title: t("header.vouchers"), ref: voucherRef, visibility: currentCityConfig.voucherSection.isVisible },
      { title: t("header.cooperation"), ref: cooperationRef, visibility: currentCityConfig.cooperationSection.isVisible },
      { title: t("header.reviews"), ref: reviewsRef, visibility: currentCityConfig.reviewSection.isVisible },
      { title: t("header.faq"), ref: faqRef, visibility: currentCityConfig.faqSection.isVisible },
      { title: t("header.career"), ref: careerRef, visibility: currentCityConfig.careerSection.isVisible },
      { title: t("header.contact"), ref: contactRef, visibility: currentCityConfig.contactSection.isVisible },
    ];

      sectionsArray = sectionsArray.filter(
        (section) => section.visibility === true
      );

    return sectionsArray;
  }, [i18n.language]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [sections]);

  const selectSection = (title, ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleScroll = useCallback(
    (event) => {
      for (let i = sections.length - 1; i >= 0; --i) {
        if (!sections[i].ref) {
          continue;
        }
        const current = sections[i].ref.current;

        if (
          current &&
          current.getBoundingClientRect().top <
            Math.round(window.innerHeight / 2)
        ) {
          setSelectedSection(sections[i].title);
          break;
        }
      }
    },
    [sections]
  );

  const homeContent = t("homeSection.content", {
    returnObjects: true,
  });

  const handleClick = () => {
    if(currentCityConfig.bookingSection.isBookLinkEnabled) {
      navigate(`/${city}/booking`);
    }
    else {
      newsletterRef.current.scrollIntoView({behavior: "smooth", block: "center"});
    }
  }

  const [selectedSection, setSelectedSection] = useState(sections[0].title);
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const isMobile = window.matchMedia("(max-width: 1060px)").matches;

  if (!currentCityConfig) return <></>;

  return (
    <div className={css.MainPage}>
      <Header
        sections={sections}
        selectedSection={selectedSection}
        selectSection={selectSection}
        setMobileMenuVisibility={setMobileMenuVisibility}
      />
      <div className={css.languageChooserContainer}>
        <LanguageChooser
          className={css.languageChooser}
          shouldScrollUp
          borderColor="blue"
        />
      </div>
      <div className={css.recaptchaContainer} id="recaptchaContainer" />
      {currentCityConfig.mainSection.plan4uApiUrl &&
        bookingId &&
        personId &&
        shouldShowCancelOverlay && (
          <CancelOverlay
            setShouldShowCancelOverlay={setShouldShowCancelOverlay}
            bookingId={bookingId}
            personId={personId}
            apiUrl={currentCityConfig.mainSection.plan4uApiUrl}
            onSuccess={() => {
              setToastText(t("shared.cancelled"));
              setRequestFailed(false);
            }}
            onFail={() => {
              setToastText(t("shared.cancelError"));
              setRequestFailed(true);
            }}
          />
        )}
      {toastText && <Toast text={toastText} failed={requestFailed} />}
      {isFinite(mobileMenuVisibility) && (
        <MobileMenu
          mobileMenuVisibility={mobileMenuVisibility}
          setMobileMenuVisibility={setMobileMenuVisibility}
          sections={sections}
          selectedSection={selectedSection}
          selectSection={selectSection}
          cityName={currentCityConfig.cityOptions.title}
        />
      )}
      <div ref={homeRef} className={css.home}>
        <div
          className={cn(css.homeBackground, { [css.hide]: !isVideoLoading })}
        >
          {!isMobile && <div className={css.spinner} />}
        </div>
        {isIos() ? (
          <div className={css.iframeContainer}>
            <iframe
              src={
                isMobile
                  ? vimeoMobileBackgroundVideoURL
                  : vimeoBackgroundVideoURL
              }
              data-read="true"
              frameBorder="0"
              allow="autoplay"
              // allowFullScreen
              className={css.homeVideo}
              onCanPlay={() => setIsVideoLoading(false)}
            ></iframe>
            <div className={css.iframeBlend} />
          </div>
        ) : (
          <video
            loop
            autoPlay
            muted
            className={css.homeVideo}
            playsInline
            // poster={backgroundPlaceholder}
            onCanPlay={() => setIsVideoLoading(false)}
            src={isMobile ? backgroundMobileVideo : backgroundVideo}
          >
            <source
              src={isMobile ? backgroundMobileVideo : backgroundVideo}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        )}
        <div className={css.homeContentContainer}>
          <Text
            type="white"
            className={css.homeContentTitle}
            secondaryFamily
            bold
          >
            {t(`homeSection.title.${city}`)}
          </Text>
          {homeContent.map((el) => (
            <Text key={el} type="lightBlue" className={css.homeContent}>
              {el}
            </Text>
          ))}
          <Button
            text={t("shared.buyTicket")}
            onClick={handleClick}
            className={css.buyTicketButton}
          />
          <div className={css.resetLocationButtonContainer}>
            <ResetLocationButton
              cityName={currentCityConfig.cityOptions.title}
              className={css.resetLocationButton}
              textClassName={css.resetLocationButtonText}
              locationPinClassName={css.resetLocationButtonPin}
            />
          </div>
        </div>
      </div>
      <div className={css.relativeContainer}>
        {isNewsletterRequestLoading && <LoadingOverlay />}
        <div className={css.mainContent}>
          <img
            title="Shadow decoration"
            src={secondShadow}
            alt="second shadow decoration"
            className={css.secondShadow}
          />
          <AboutSection
            passedRef={aboutRef}
            images={currentCityConfig.aboutSection.aboutUsImages}
            mobileImages={currentCityConfig.aboutSection.aboutUsMobileImages}
          />
          {currentCityConfig.priceSection.isVisible && <PriceListSection
            passedRef={priceListRef}
            bookingUrl={`/${city}/booking`}
            locations={locations}
            priceBanner={currentCityConfig.priceSection.priceBanner[i18n.language]}
          />}
          {currentCityConfig.descriptionSection.isVisible && <DescriptionSection />}
          {currentCityConfig.reviewSection.isVisible && <VoucherSection passedRef={voucherRef} />}
          
          {isCooperationSectionVisible && (
            <CooperationSection passedRef={cooperationRef} />
          )}
          
          {currentCityConfig.reviewSection.isVisible && <ReviewSection passedRef={reviewsRef} />}
          
          <FAQSection passedRef={faqRef} />
          {currentCityConfig.careerSection.isVisible && <CareerSection passedRef={careerRef} />}
          
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_NEWSLETTER_RECAPTCHA_KEY}
            container={{
              element: "recaptchaContainer",
              parameters: {
                badge: "bottomleft",
                size: "invisible",
              },
            }}
          >
          <NewsletterSection passedRef={newsletterRef} setIsLoading={setIsNewsletterRequestLoading} />
          </GoogleReCaptchaProvider>
        </div>
        <img
          title="Shadow decoration"
          src={firstShadow}
          alt="first shadow decoration"
          className={css.firstShadow}
        />
      </div>
          {currentCityConfig.contactSection.isVisible && 
          <ContactSection
            passedRef={contactRef}
            address={currentCityConfig.contactSection.address}
            email={currentCityConfig.contactSection.email}
          />}
      {currentCityConfig.contactSection.isMapVisible && <GoogleMaps googleMapsOptions={currentCityConfig.contactSection.googleMapsOptions} />}
      
      <Footer
        scrollCallback={() => selectSection(sections[0].title, homeRef)}
      />
    </div>
  );
};
