import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "shared/components/Text";
import css from "./styles.module.scss";
import { CooperationItem } from "./CooperationItem";

import integrationImage from "assets/images/integration.png";
import birthdayImage from "assets/images/birthday.png";
import schoolImage from "assets/images/school.png";
import { appStore } from "store";
import { getCity, getLocations } from "store/selectors";

export const CooperationSection = ({ passedRef }) => {
  const { t } = useTranslation();

  const { locations } = appStore.useState(getLocations);
  const { city } = appStore.useState(getCity);

  const currentCityConfig = useMemo(() => {
    return locations.cities[city];
  }, [city, locations]);

  const content = t("cooperationSection.content", {
    returnObjects: true,
  });

  return (
    <div ref={passedRef} className={css.CooperationSection}>
      <Text type="white" className={css.title} bold secondaryFamily>
        {t("cooperationSection.title")}
      </Text>
      {t("cooperationSection.subTitle") && (
        <Text type="white" className={css.subTitle}>
          {t(`cooperationSection.subTitle.${city}`)}
        </Text>
      )}
      <div className={css.linksContainer}>
        {currentCityConfig.cooperationSection.integrationOfferVisibility && (
          <CooperationItem
            href={currentCityConfig.cooperationSection.isHrefEnabled ? `/${city}/integration` : null}
            image={integrationImage}
            title={t("cooperationSection.integrationParties")}
          />
        )}
        {currentCityConfig.cooperationSection.birthdayOfferVisibility && (
          <CooperationItem
            href={currentCityConfig.cooperationSection.isHrefEnabled ? `/${city}/birthday` : null}
            image={birthdayImage}
            title={t("cooperationSection.birthdays")}
          />
        )}
        {currentCityConfig.cooperationSection.schoolOfferVisibility && (
          <CooperationItem
            href={currentCityConfig.cooperationSection.isHrefEnabled ? `/${city}/school` : null}
            image={schoolImage}
            title={t("cooperationSection.schools")}
          />
        )}
      </div>
      {content.map((el, index) => (
        <Text key={index} type="pink" className={css.content}>
          {el}
        </Text>
      ))}
    </div>
  );
};
