import { appStore } from ".";

export const setCity = ({ city }) => {
  appStore.update((s) => {
    s.city = city;
  });
};

export const setLocations = ({ locations }) => {
  appStore.update((s) => {
    s.locations = locations;
  });
};
