import { useNavigate } from "react-router-dom";

import { IconLink } from "shared/components/IconLink";
import logo from "assets/images/header-logo.png";
import facebookIcon from "assets/icons/facebook.png";
import facebookHoverIcon from "assets/icons/facebook-hover.png";
import instagramIcon from "assets/icons/instagram.png";
import instagramHoverIcon from "assets/icons/instagram-hover.png";
import tiktokIcon from "assets/icons/tiktok.png";
import tiktokHoverIcon from "assets/icons/tiktok-hover.png";
import { LanguageChooser } from "shared/components/LanguageChooser";
import css from "./styles.module.scss";
import { ResetLocationButton } from "shared/components/ResetLocationButton";

export const Header = ({ city, cityName }) => {
  const navigate = useNavigate();

  return (
    <div className={css.Header}>
      <button
        onClick={() => navigate(`/${city}`)}
        className={css.headerLogoButton}
      >
        <img src={logo} alt="" className={css.headerLogo} />
      </button>
      {cityName && (
        <div className={css.resetLocationButtonContainer}>
          <ResetLocationButton cityName={cityName} />
        </div>
      )}
      <div className={css.iconsContainer}>
        <IconLink
          href="https://www.facebook.com/pixelxlpoland"
          icon={facebookIcon}
          iconHovered={facebookHoverIcon}
          newTab
          className={css.icon}
        />
        <IconLink
          href="https://www.instagram.com/pixelxlpoland/"
          icon={instagramIcon}
          iconHovered={instagramHoverIcon}
          newTab
          className={css.icon}
        />
        <IconLink
          href="https://www.tiktok.com/@pixelxlpoland"
          icon={tiktokIcon}
          iconHovered={tiktokHoverIcon}
          newTab
          className={css.icon}
        />
        <div className={css.languageChooserContainer}>
          <LanguageChooser className={css.languageChooser} borderColor="blue" />
        </div>
      </div>
      
    </div>
  );
};
