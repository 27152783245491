import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

import { MainPage } from "features/MainPage";
import { BirthdayPage } from "features/events/BirthdayPage";
import { IntegrationPage } from "features/events/IntegrationPage";
import { SchoolPage } from "features/events/SchoolPage";
import { BookingPage } from "features/BookingPage";
import { VoucherPage } from "features/VoucherPage";
import { UserDataFormPage } from "features/UserDataFormPage";
import { Menu } from "features/Menu";
import { LocationsPage } from "features/LocationsPage";
import { LocationInProgressPage } from "features/LocationInProgressPage";
import { CityLayout } from "shared/components/CityLayout";
import { LocationInProgressWithoutCountdownPage } from "features/LocationInProgressWithoutCountdownPage";
import { appStore } from "store";
import { getLocations } from "store/selectors";

import css from "./styles.module.scss";
import { setLocations } from "store/actions";
import { LoadingOverlay } from "shared/components/LoadingOverlay";

export const App = () => {
  const { i18n } = useTranslation();
  const { locations } = appStore.useState(getLocations);

  useEffect(() => {
    const loadLocations = async () => {
      const countryId = process.env.REACT_APP_COUNTRY_ID || "pl";
      const { getLocationsData } = await import(
        `shared/locations/${countryId}.js`
      );
      const allLocations = getLocationsData();
      setLocations({ locations: allLocations });
    };

    loadLocations();
  }, [i18n.language]);

  if (!Object.keys(locations).length) {
    return <LoadingOverlay withBackground />;
  }

  return (
    <BrowserRouter>
      <div className={css.App}>
        <Routes>
          <Route path="/" element={<LocationsPage />} />
          <Route path="/cancel" element={<LocationsPage />} />
          <Route
            path="/success"
            element={<LocationsPage isSuccessInUrl={true} />}
          />
          <Route path=":city" element={<CityLayout />}>
            <Route index element={<MainPage />} />
            <Route path=":reservationStatus" element={<MainPage />} />
            <Route path="cancel" element={<MainPage />} />
            <Route path="booking" element={<BookingPage />} />
            <Route path="birthday" element={<BirthdayPage />} />
            <Route path="integration" element={<IntegrationPage />} />
            <Route path="school" element={<SchoolPage />} />
            <Route path="vouchers" element={<VoucherPage />} />
            <Route path="form" element={<UserDataFormPage />} />
            <Route path="menu" element={<Menu />} />
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
};
