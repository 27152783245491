import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { setupPlan4u } from "shared/utils";
// import banner from "assets/images/banners/banner-voucher-pl.png";

import { appStore } from "store";
import { getCity, getLocations } from "store/selectors";

import { Header } from "./Header";
import css from "./styles.module.scss";

export const VoucherPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { locations } = appStore.useState(getLocations);
  const { city } = appStore.useState(getCity);

  const siteTitle = locations.cities[city].cityOptions.siteTitle;
  const banner = locations.cities[city].voucherSection.voucherBanner[i18n.language];

  useEffect(() => {
    setupPlan4u();
    document.title = `${siteTitle} - Vouchers`;
  }, []);

  const voucherPageUrl = useMemo(() => {
    return locations.cities[city].voucherSection.voucherPageUrl;
  }, [locations, city]);

  return (
    <div className={css.VoucherPage}>
      <Header city={city} cityName={locations.cities[city].cityOptions.title} />
      <img src={banner} className={css.banner} />
      <iframe
        id="fBookIt"
        src={voucherPageUrl}
        width="100%"
        frameBorder="0"
        scrolling="no"
        className={css.iframe}
      ></iframe>
    </div>
  );
};
