import { useState } from "react";
import cn from "classnames";
import { useNavigate } from "react-router-dom";

import { Text } from "shared/components/Text";
import { IconLink } from "shared/components/IconLink";
import logo from "assets/images/header-logo.png";
import selectedLinkMark from "assets/icons/selected-link-mark.png";
import facebookIcon from "assets/icons/facebook.png";
import facebookHoverIcon from "assets/icons/facebook-hover.png";
import instagramIcon from "assets/icons/instagram.png";
import instagramHoverIcon from "assets/icons/instagram-hover.png";
import tiktokIcon from "assets/icons/tiktok.png";
import tiktokHoverIcon from "assets/icons/tiktok-hover.png";
import mobileMenu from "assets/icons/mobile-menu.png";
import mobileMenuHover from "assets/icons/mobile-menu-hover.png";

import css from "./styles.module.scss";
import { LanguageChooser } from "shared/components/LanguageChooser";
import { ResetLocationButton } from "shared/components/ResetLocationButton";
import { appStore } from "store";
import { getLocations } from "store/selectors";

export const Header = ({
  sections,
  selectedSection,
  selectSection,
  setMobileMenuVisibility,
}) => {
  const navigate = useNavigate();
  const { locations } = appStore.useState(getLocations);

  const socials = locations.countryOptions.socials;

  return (
    <div className={css.Header}>
      <div className={css.logoContainer}>
        <button
          onClick={() => selectSection(sections[0].title, sections[0].ref)}
          className={css.headerLogoButton}
        >
          <img
            title="Header logo"
            src={logo}
            alt="header logo"
            className={css.headerLogo}
          />
        </button>
      </div>
      <div className={css.linksContainer}>
        {sections.map(({ title, ref, url }) => (
          <button
            key={title}
            onClick={() => {
              if (ref) {
                selectSection(title, ref);
              } else if (url) {
                navigate(url);
              }
            }}
            className={css.link}
          >
            <Text
              type="white"
              className={cn(css.sectionText, {
                [css.selectedSectionText]: title === selectedSection,
              })}
            >
              {title}
            </Text>
            <img
              title="Mark for selected section"
              src={selectedLinkMark}
              alt="mark for selected section"
              className={cn(css.selectedLinkMark, {
                [css.hidden]: title !== selectedSection,
              })}
            />
          </button>
        ))}
      </div>
      <div className={css.iconsContainer}>
        <IconLink
          href={socials.facebook}
          icon={facebookIcon}
          iconHovered={facebookHoverIcon}
          newTab
          className={css.icon}
        />
        <IconLink
          href={socials.instagram}
          icon={instagramIcon}
          iconHovered={instagramHoverIcon}
          newTab
          className={css.icon}
        />
        <IconLink
          href={socials.tiktok}
          icon={tiktokIcon}
          iconHovered={tiktokHoverIcon}
          newTab
          className={css.icon}
        />
      </div>
      <div className={css.languageChooserContainer}>
        <LanguageChooser className={css.languageChooser} borderColor="blue" />
      </div>
      <div className={css.mobileMenuButtonContainer}>
        <button
          className={css.mobileMenuButton}
          onClick={() => setMobileMenuVisibility(true)}
        >
          <img
            title="Mobile menu trigger"
            src={mobileMenu}
            alt="mobile menu trigger"
            className={css.mobileMenuButtonIcon}
            onTouchStart={(e) => {
              e.target.src = mobileMenuHover;
            }}
            onTouchEnd={(e) => {
              e.target.src = mobileMenu;
            }}
          />
        </button>
      </div>
    </div>
  );
};
