import { useTranslation } from "react-i18next";

import { Text } from "shared/components/Text";
import sectionSeparator from "assets/images/section-separator.png";
import phoneIcon from "assets/icons/phone.png";
import mailIcon from "assets/icons/mail.png";
import addressIcon from "assets/icons/address.png";
import { getLocations } from "store/selectors";
import css from "./styles.module.scss";
import { appStore } from "store";

export const ContactSection = ({ passedRef, address, email }) => {
  const { t } = useTranslation();

  const { locations } = appStore.useState(getLocations);

  const telephoneNumber = locations.countryOptions.telephoneNumber;

  return (
    <div ref={passedRef} className={css.ContactSection}>
      <div className={css.contentBox}>
        <Text type="black" className={css.title} bold secondaryFamily>
          {t("contactSection.title")}
        </Text>
        {t("contactSection.subTitle") && (
          <Text type="darkBlue" className={css.subTitle}>
            {t("contactSection.subTitle")}
          </Text>
        )}
        <div className={css.contactContainer}> 
          <div className={css.contactColumn}>
            <div className={css.contactEntry}>
              <img
                src={phoneIcon}
                alt="phone icon"
                title="Phone icon"
                className={css.phoneIcon}
              />
              <a href={telephoneNumber}>
                <Text type="black" className={css.contact}>
                  {telephoneNumber}
                </Text>
              </a>
            </div>
            <div className={css.contactEntry}>
              <img
                src={mailIcon}
                alt="mail icon"
                title="Mail icon"
                className={css.mailIcon}
              />
              <a href={`mailto:${email}`}>
                <Text type="black" className={css.contact}>
                  {email}
                </Text>
              </a>
            </div>
            <div className={css.contactEntry}>
              <img
                src={addressIcon}
                alt="address icon"
                title="Address icon"
                className={css.addressIcon}
              />
              <Text type="black" className={css.contact}>
                {address}
              </Text>
            </div>
          </div>
          <div className={css.contactColumn}>
              <Text type="black" className={css.contact} bold>
                {t("contactSection.workingTitle")}
              </Text>
              <div className={css.contactRow}>
                <Text type="black" className={css.contact}>
                  {t("contactSection.daysFromMondayToFriday")}
                </Text>
                <Text type="black" className={css.contact}>
                  {locations.countryOptions.workingHours.mondayToFriday}
                </Text>
              </div>
              <div className={css.contactRow}>
                <Text type="black" className={css.contact}>
                  {t("contactSection.saturday")}
                </Text>
                <Text type="black" className={css.contact}>
                  {locations.countryOptions.workingHours.saturday}
                </Text>
              </div>
              <div className={css.contactRow}>
                <Text type="black" className={css.contact}>
                  {t("contactSection.sunday")}
                </Text>
                <Text type="black" className={css.contact}>
                  {locations.countryOptions.workingHours.sunday}
                </Text>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};
