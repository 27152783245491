import cn from "classnames";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import polishFlagIcon from "assets/icons/flags/pl-v3.svg";
import englishFlagIcon from "assets/icons/flags/eng-v3.svg";
import germanFlagIcon from "assets/icons/flags/de-v2.svg";

// icons source: https://github.com/lipis/flag-icons

import css from "./styles.module.scss";
import { useRef } from "react";

const countryId = process.env.REACT_APP_COUNTRY_ID || "pl";

const initialLanguageOptions = {
  "pl": [
    {
      languagePrefix: "pl",
      flag: polishFlagIcon,
    },
    {
      languagePrefix: "eng",
      flag: englishFlagIcon,
    },
    {
      languagePrefix: "de",
      flag: germanFlagIcon,
    },
  ],
  "de": [
    {
      languagePrefix: "eng",
      flag: englishFlagIcon,
    },
    {
      languagePrefix: "de",
      flag: germanFlagIcon,
    },  
  ]
};

const DEFAULT_LANGUAGE = "pl";
const DEFAULT_OFFSET_TOP = 40;
const MEDIUM_OFFSET_TOP = 55;
const LARGE_OFFSET_TOP = 70;

export const LanguageChooser = ({
  className,
  borderColor = "white",
  shouldScrollUp = false,
}) => {
  const { i18n } = useTranslation();

  const buttonRef = useRef(null);

  const getOffsetTopValue = () => {
    if (window.matchMedia("(min-width: 2160px)").matches) {
      return LARGE_OFFSET_TOP;
    } else if (window.matchMedia("(max-width: 990px)").matches) {
      return MEDIUM_OFFSET_TOP;
    }
    return DEFAULT_OFFSET_TOP;
  };

  const [languageOptions, setLanguageOptions] = useState(
    initialLanguageOptions[countryId].filter((el) => el.languagePrefix !== i18n.language)
  );
  const [offsetTop, setOffsetTop] = useState(0);
  const [pickedLanguageIndex, setPickedLanguageIndex] = useState(-1);
  const [canOpenFlagDropdown, setCanOpenFlagDropdown] = useState(true);

  const [isBlurred, setIsBlurred] = useState(false);

  const [pickedLanguage, setPickedLanguage] = useState(
    initialLanguageOptions[countryId].find(
      (el) => el.languagePrefix === (i18n.language || DEFAULT_LANGUAGE)
    )
  );

  useEffect(() => {
    if (isBlurred && canOpenFlagDropdown) {
      if (pickedLanguageIndex === -1) {
        setOffsetTop(0);
      } else {
        callback(pickedLanguageIndex);
      }
    }
  }, [isBlurred]);

  const callback = (index) => {
    if (!canOpenFlagDropdown) {
      return;
    }
    setOffsetTop(0);
    setCanOpenFlagDropdown(false);
    i18n.changeLanguage(languageOptions[index].languagePrefix);
    window.localStorage.setItem(
      "i18nextLng",
      languageOptions[index].languagePrefix
    );

    // race condition hack - TODO: refactor
    setTimeout(() => {
      setPickedLanguage(languageOptions[index]);
      setPickedLanguageIndex(-1);
    }, 400);

    setTimeout(() => {
      const previousPickedLanguage = pickedLanguage;
      setLanguageOptions((languageOptions) => {
        const newLanguageOptions = [...languageOptions];
        newLanguageOptions[index] = previousPickedLanguage;
        return newLanguageOptions;
      });
      setCanOpenFlagDropdown(true);
    }, 800);
  };

  const toggleFlagDropdown = () => {
    if (canOpenFlagDropdown) {
      setOffsetTop((offset) => (offset !== 0 ? 0 : getOffsetTopValue()));
    }
  };

  return (
    <div className={css.LanguageChooser}>
      <button
        ref={buttonRef}
        className={cn(css.flagButton, className)}
        onClick={() => {
          buttonRef.current.focus();
          setIsBlurred(false);
          toggleFlagDropdown();
        }}
        style={{ zIndex: 1001 }}
        onBlur={() => {
          setIsBlurred(true);
        }}
      >
        <img
          src={pickedLanguage.flag}
          alt=""
          className={cn(css.flagIcon, {
            [css.whiteIconBorder]: borderColor === "white",
            [css.blueIconBorder]: borderColor === "blue",
          })}
        />
      </button>
      {languageOptions.map((el, i) => (
        <FlagButton
          key={el.languagePrefix}
          canOpenFlagDropdown={canOpenFlagDropdown}
          setPickedLanguageIndex={() => setPickedLanguageIndex(i)}
          pickedLanguageIndex={pickedLanguageIndex}
          marginTop={
            shouldScrollUp
              ? `${offsetTop * (i + 1) * -1}px`
              : `${offsetTop * (i + 1)}px`
          }
          borderColor={borderColor}
          zIndex={pickedLanguageIndex === i ? 1010 : 1000 - i}
          flag={el.flag}
        />
      ))}
    </div>
  );
};

const FlagButton = ({
  canOpenFlagDropdown,
  setPickedLanguageIndex,
  zIndex,
  marginTop,
  borderColor,
  flag,
}) => {
  return (
    <button
      onPointerDown={() => {
        if (canOpenFlagDropdown) {
          setPickedLanguageIndex();
        }
      }}
      style={{
        zIndex,
        marginTop,
      }}
      className={css.flagButton}
    >
      <img
        src={flag}
        alt=""
        className={cn(css.flagIcon, {
          [css.whiteIconBorder]: borderColor === "white",
          [css.blueIconBorder]: borderColor === "blue",
        })}
      />
    </button>
  );
};
