import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { Text } from "shared/components/Text";
import { Button } from "shared/components/Button";
import refreshIcon from "assets/icons/refresh.png";

import { NewsletterInput } from "./NewsletterInput";
import css from "./styles.module.scss";
import { newsletterApi } from "./newsletterApi";
import { useCallback } from "react";
import { appStore } from "store";
import { getLocations, getCity } from "store/selectors";

const emailPattern =
  /^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;

export const NewsletterSection = ({ setIsLoading, passedRef }) => {
  const { t, i18n } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { locations } = appStore.useState(getLocations);
  const { city } = appStore.useState(getCity);
  const countryId = process.env.REACT_APP_COUNTRY_ID || "pl";

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.error("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("submitNewUser");
    return token;
  }, [executeRecaptcha]);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [requestError, setRequestError] = useState("");
  const [isSigned, setIsSigned] = useState(false);

  const newsletterCustomHeading = locations.cities[city].cityOptions.newsletterCustomHeading;

  const submit = async () => {
    setRequestError("");

    const emailTrimmed = email.trim();

    if (!emailTrimmed) {
      setEmailError(t("newsletterSection.errors.emptyEmail"));
      return;
    } else if (!emailPattern.test(emailTrimmed)) {
      setEmailError(t("newsletterSection.errors.incorrectEmail"));
      return;
    } else if (emailTrimmed.length > 64) {
      setEmailError(t("newsletterSection.errors.emailTooLong"));
      return;
    }

    setEmailError("");
    setIsLoading(true);

    try {
      const token = await handleReCaptchaVerify();

      await newsletterApi.createContact({
        email,
        language: i18n.language,
        token,
        countryId: countryId,
      });
      setIsSigned(true);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      if (e.response && e.response.status === 409) {
        setRequestError(t("newsletterSection.errors.alreadyExists"));
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      setRequestError(t("newsletterSection.errors.connectionError"));
    }
  };

  return (
    <div className={css.NewsletterSection} ref={passedRef}>
      {!isSigned ? (
        <>
          <Text type="white" className={css.subTitle} bold secondaryFamily>
            {newsletterCustomHeading ?? t("newsletterSection.title")}
          </Text>
          <NewsletterInput
            email={email}
            setEmail={setEmail}
            error={emailError}
          />
          <Button onClick={submit} text={t("newsletterSection.save")} />
          <Text className={css.error} secondaryFamily={true} type="errorRed">
            {requestError}
          </Text>
        </>
      ) : (
        <>
          <div className={css.signedTopContainer}>
            <Text type="white" className={css.subTitle} bold secondaryFamily>
              {t("newsletterSection.signedTitle")}
            </Text>
            <button
              onClick={() => {
                setIsSigned(false);
                setEmail("");
              }}
            >
              <img
                src={refreshIcon}
                alt="refresh"
                className={css.refreshIcon}
              />
            </button>
          </div>
          <Text type="white" className={css.prompt} bold secondaryFamily>
            {t("newsletterSection.signedPrompt")}
          </Text>
        </>
      )}
    </div>
  );
};
