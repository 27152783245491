import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

import { Text } from "shared/components/Text";
import { Button } from "shared/components/Button";
import { appStore } from "store";
import { getCity } from "store/selectors";

import css from "./styles.module.scss";

export const DescriptionSection = () => {

  const { t } = useTranslation();
  const { city } = appStore.useState(getCity);

  const contentAfterPriceList = t(
    `priceListSection.contentAfterPriceList.${city}`,
    {
      returnObjects: true,
    }
  );

  return (
    <div className={css.DescriptionSection}>
      {contentAfterPriceList.map((el) => (
        <Text key={el} type="white" className={css.content}>
          {el}
        </Text>
      ))}
    </div>
  );
};
