import cn from "classnames";
import { Spinner } from "shared/components/Spinner";

import css from "./styles.module.scss";

export const LoadingOverlay = ({ withBackground = false }) => {
  return (
    <div className={cn(css.overlay, { [css.withBackground]: withBackground })}>
      <Spinner />
    </div>
  );
};
