import css from "./styles.module.scss";

export const GoogleMaps = ({ googleMapsOptions }) => {
  return (
    <iframe
      className={css.GoogleMaps}
      allowFullScreen
      src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}&q=PixelXL,${googleMapsOptions.city},${googleMapsOptions.street},${googleMapsOptions.country}`}
    />
  );
};
