import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { appStore } from "store";
import { setCity } from "store/actions";
import { getCity, getLocations } from "store/selectors";
import { LoadingOverlay } from "../LoadingOverlay";
import { LocationInProgressWithoutCountdownPage } from "features/LocationInProgressWithoutCountdownPage";

export const CityLayout = () => {
  const { city } = useParams();
  const { city: cityFromStore } = appStore.useState(getCity);
  const { locations } = appStore.useState(getLocations);

  useEffect(() => {
    setCity({ city });
  }, [city]);

  if (!cityFromStore) {
    return <LoadingOverlay withBackground />;
  }

  if (locations.cities[cityFromStore].cityOptions.isCityInProgress) {
    return <LocationInProgressWithoutCountdownPage city={cityFromStore} />;
  }
  
  return <Outlet />;
};
