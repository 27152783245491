import cn from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Text } from "shared/components/Text";
import faqArrow from "assets/images/arrow-down.png";
import pixelBoxIcon from "assets/images/icon-pixel-box-3.png";
import { appStore } from "store";
import { getCity } from "store/selectors";

import css from "./styles.module.scss";

export const FAQSection = ({ passedRef }) => {
  const { t, i18n } = useTranslation();
  const [content, setContent] = useState([]);

  const { city } = appStore.useState(getCity);

  useEffect(() => {
    const contentFromTranslations = t(`faqSection.content.${city}`, {
      returnObjects: true,
    });
    contentFromTranslations.map((el) => {
      el.isAnswerVisible = false;
    });
    setContent(contentFromTranslations);
  }, [i18n.language, city]);

  return (
    <div ref={passedRef} className={css.FAQSection}>
      <div className={css.contentBox}>
        <Text type="white" className={css.title} bold secondaryFamily>
          {t("faqSection.title")}
        </Text>
        {t("faqSection.subTitle") && (
          <Text type="white" className={css.subTitle}>
            {t(`faqSection.subTitle.${city}`)}
          </Text>
        )}
        {city === "warszawa" ? (
          <img src={pixelBoxIcon} className={css.pixelBoxIcon}></img>
        ) : (
          ""
        )}
        {content.map((el, i) => {
          return (
            <div key={i} className={css.contentItemContainer}>
              <button
                onClick={() => {
                  setContent((prev) => {
                    const newContent = prev.map((el, index) => {
                      if (index === i) {
                        el.isAnswerVisible = !el.isAnswerVisible;
                      }
                      return el;
                    });
                    return newContent;
                  });
                }}
                className={cn(css.contentQuestionContainer, {
                  [css.contentQuestionContainerLighter]: i % 2,
                })}
              >
                <Text
                  type="white"
                  className={css.contentQuestion}
                  secondaryFamily
                  bold
                >
                  {i + 1}. {el.question}
                </Text>
                <img
                  src={faqArrow}
                  alt="faq arrow"
                  className={cn(css.faqArrowImage, {
                    [css.reversed]: el.isAnswerVisible,
                  })}
                />
              </button>
              {el.isAnswerVisible && (
                <Text type="white" className={css.content}>
                  {el.answer}
                </Text>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
