import { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { Button } from "shared/components/Button";
import { Text } from "shared/components/Text";
import { RadioButton } from "shared/components/RadioButton";
import { Spinner } from "../Spinner";

import css from "./styles.module.scss";
import { Checkbox } from "shared/components/Checkbox";
import { collectUserData } from "../formApi";
import { useSearchParams } from "react-router-dom";

export const FinalConsentStep = ({
  email,
  name,
  surname,
  nick,
  dateOfBirth,
  zipCode,
  setStep,
}) => {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [searchParams, setSearchParams] = useSearchParams();
  const city = searchParams.get("city") || "wroclaw";
  const statue = `documents/${city}/statue.pdf`
  
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.error("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("submitNewUser");
    return token;
  }, [executeRecaptcha]);

  const [withChildren, setWithChildren] = useState();
  const [childrenAgreement, setChildrenAgreement] = useState(false);
  const [healthConsent, setHealthConsent] = useState(false);
  const [healthConsentWithChildren, setHealthConsentWithChildren] = useState(false);
  const [statueConsent, setStatueConsent] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false);

  const [connectionError, setConnectionError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setConnectionError("");
    setIsLoading(true);
    try {
      const token = await handleReCaptchaVerify();

      await collectUserData({
        email,
        name,
        surname,
        nick,
        dateOfBirth,
        zipCode: zipCode.trim(),
        withChildren,
        marketingConsent,
        token,
        city,
      });
      setStep("thankYou");
    } catch (e) {
      setConnectionError(t("form.errors.connectionError"));
    }
    setIsLoading(false);
  };

  return (
    <div className={css.FinalConsentStep}>
      <Text className={css.prompt} type="pink" secondaryFamily alignCenter>
        {t("form.finalStepPrompt")}
      </Text>
      <Text className={css.prompt} type="lightBlue" secondaryFamily alignCenter>
        {t("form.consentPrompt")}
      </Text>
      <div className={css.radioButtonContainer}>
        <div className={css.labelContainer}>
          <Text
            className={css.consentPrompt}
            type="black"
            alignCenter
            secondaryFamily
          >
            {t("form.withChildrenPrompt")}
          </Text>
        </div>
        <div className={css.innerRadioButtonContainer}>
          <RadioButton
            value="yes"
            name="children"
            label={t("form.yes")}
            className={css.radioButton}
            onChange={() => setWithChildren(true)}
          />
          <RadioButton
            value="no"
            name="children"
            label={t("form.no")}
            className={css.radioButton}
            onChange={() => setWithChildren(false)}
          />
        </div>
      </div>
      <div>
        {withChildren === true && (
          <>
            <button
              className={css.checkboxContainerButton}
              onClick={() => setChildrenAgreement((fcc) => !fcc)}
            >
              <div className={css.labelContainer}>
                <div className={css.innerCheckboxContainer}>
                  <Checkbox
                    value={childrenAgreement}
                    onChange={(e) => {}}
                    className={css.checkbox}
                  />
                </div>
                <Text
                  className={css.consentPrompt}
                  type="black"
                  alignCenter
                  secondaryFamily
                >
                  {t("form.childrenConsentPrompt")}
                </Text>
              </div>
            </button>
            <button
              className={css.checkboxContainerButton}
              onClick={() => setHealthConsentWithChildren((fcc) => !fcc)}
            >
              <div className={css.labelContainer}>
                <div className={css.innerCheckboxContainer}>
                  <Checkbox
                    value={healthConsentWithChildren}
                    onChange={(e) => {}}
                    className={css.checkbox}
                  />
                </div>
                <Text
                  className={css.consentPrompt}
                  type="black"
                  alignCenter
                  secondaryFamily
                >
                  <Trans
                    i18nKey="form.childrenAndUserHealthConsentPrompt"
                    components={[
                      <a
                        className={css.link}
                        href="https://pixel-xl.com/documents/health-contraindications.pdf"
                        target="_blank"
                      />,
                    ]}
                  />
                </Text>
              </div>
            </button>
          </>
        )}
        {withChildren === false && (
          <button
            className={css.checkboxContainerButton}
            onClick={() => setHealthConsent((fcc) => !fcc)}
          >
            <div className={css.labelContainer}>
              <div className={css.innerCheckboxContainer}>
                <Checkbox
                  value={healthConsent}
                  onChange={(e) => {}}
                  className={css.checkbox}
                />
              </div>
              <Text
                className={css.consentPrompt}
                type="black"
                alignCenter
                secondaryFamily
              >
                <Trans
                  i18nKey="form.healthConsentPrompt"
                  components={[
                    <a
                      className={css.link}
                      href="https://pixel-xl.com/documents/health-contraindications.pdf"
                      target="_blank"
                    />,
                  ]}
                />
              </Text>
            </div>
          </button>
        )}
        {isFinite(withChildren) && (
          <>
            <button
              className={css.checkboxContainerButton}
              onClick={() => setStatueConsent((fcc) => !fcc)}
            >
              <div className={css.labelContainer}>
                <div className={css.innerCheckboxContainer}>
                  <Checkbox
                    value={statueConsent}
                    onChange={(e) => {}}
                    className={css.checkbox}
                  />
                </div>
                <Text
                  className={css.consentPrompt}
                  type="black"
                  alignCenter
                  secondaryFamily
                >
                  <Trans
                    i18nKey="form.acceptStatuePrompt"
                    components={[
                      <a
                        className={css.link}
                        href={statue}
                        target="_blank"
                      />,
                    ]}
                  />
                </Text>
              </div>
            </button>
            <button
              className={css.checkboxContainerButton}
              onClick={() => setMarketingConsent((fcc) => !fcc)}
            >
              <div className={css.labelContainer}>
                <div className={css.innerCheckboxContainer}>
                  <Checkbox
                    value={marketingConsent}
                    onChange={(e) => {}}
                    className={css.checkbox}
                  />
                </div>
                <Text
                  className={css.consentPrompt}
                  type="black"
                  alignCenter
                  secondaryFamily
                >
                  <Trans
                    i18nKey="form.marketingConsentPrompt"
                    components={[
                      <a
                        className={css.link}
                        href="https://pixel-xl.com/documents/rodo.pdf"
                        target="_blank"
                      />,
                    ]}
                  />
                </Text>
              </div>
            </button>
          </>
        )}
        {((!withChildren && healthConsent) ||
          (withChildren && healthConsentWithChildren)) &&
          statueConsent &&
          (!withChildren || childrenAgreement) && (
            <Button
              text={t("form.submit")}
              className={css.button}
              textClassname={css.buttonText}
              onClick={() => onSubmit()}
            />
          )}
        {isLoading && <Spinner className={css.spinner} />}
        {connectionError && (
          <Text className={css.error} type="red" secondaryFamily alignCenter>
            {connectionError}
          </Text>
        )}
      </div>
    </div>
  );
};
